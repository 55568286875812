<template>
  <div>
    <div style="background:#03063c">
      <el-image width="1920" height="568" :src="bannerUrl"></el-image>
    </div>
    <div style="width:100%; height:68px; background:#475AFF">
      <div style="width:1320px; margin:0 auto; text-align:left">
        <ul>
          <router-link :to="{ path: '/components/web/itc/case01' }">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                class="tabStyle2"
                style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
              >
                <div>01</div>
                <div>{{ $t('Nav.quanfangweiitc') }}</div>
              </div>
            </li>
          </router-link>
          <router-link :to="{ path: '/components/web/itc/case02' }">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
              >
                <div>02</div>
                <div>{{ $t('Nav.quanqiuopo') }}</div>
              </div>
            </li>
          </router-link>
          <router-link :to="{ path: '/components/web/itc/case03' }">
            <li class="tabStyleLi">
              <div class="tabStyle" style="background:#3246F4"></div>
              <div
                style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
              >
                <div>03</div>
                <div>{{ $t('Nav.anlifenxiang') }}</div>
              </div>
            </li>
          </router-link>
        </ul>
      </div>
    </div>
    <div
      style="width:1200px; margin:0 auto; height: 44px;box-shadow: 0px 1px 0px 0px rgba(71, 90, 255, 0.1);"
    >
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }"
          ><span style="color:#303133">{{
            $t('Nav.home')
          }}</span></el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/components/web/itc/index' }">
          <span style="color:#303133">{{
            $t('Nav.itc')
          }}</span></el-breadcrumb-item
        >
        <el-breadcrumb-item
          ><span style="color:#303133">
            {{ $t('Nav.anlifenxiang') }}</span
          ></el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>

    <div style=" clear: both;"></div>
    <div style="width:1200px; margin:0 auto; height:900px">
      <div style="width:600px;text-align:left;margin-top:30px;margin-left:60px">
        <div>
          <span style="margin-right:5px">●</span>{{ $t('Nav.anlitext01') }}
        </div>
        <div>
          <span style="margin-right:5px">●</span>{{ $t('Nav.anlitext02') }}
        </div>
        <div>
          <span style="margin-right:5px">●</span>{{ $t('Nav.anlitext03') }}
        </div>
        <div>
          <span style="margin-right:5px">●</span>{{ $t('Nav.anlitext04') }}
        </div>
        <div>
          <span style="margin-right:5px">●</span>{{ $t('Nav.anlitext05') }}
        </div>
        <div>
          <span style="margin-right:5px">●</span>{{ $t('Nav.anlitext06') }}
        </div>
        <div>
          <span style="margin-right:5px">●</span>{{ $t('Nav.anlitext07') }}
        </div>
        <div>
          <span style="margin-right:5px">●</span>{{ $t('Nav.anlitext08') }}
        </div>
        <div>
          <span style="margin-right:5px">●</span>{{ $t('Nav.anlitext09') }}
        </div>
        <div>
          <span style="margin-right:5px">●</span>{{ $t('Nav.anlitext10') }}
        </div>
        <div>
          <span style="margin-right:5px">●</span>{{ $t('Nav.anlitext11') }}
        </div>
      </div>
      <div style="margin-top:20px;">
        <el-image
          :src="corporateVision3"
          style="width:1080px; height:606px;"
        ></el-image>
      </div>
    </div>
    <div style=" clear: both;margin-bottom:30px"></div>
  </div>
</template>

<script>
export default {
  name: 'case01',
  components: {},
  data() {
    return {
      bannerUrl: require('../../../assets/44.jpg'),
      corporateVision1: require('../../../assets/jifang.png'),
      corporateVision2: require('../../../assets/aboutus/corporateVision-img2.png'),
      corporateVision3: require('../../../assets/itc/anli.png'),
    }
  },
  methods: {},
}
</script>

<style>
ul {
  margin: 0;
}
.tabStyle {
  color: #ffffff;
  display: inline-block;
  height: 68px;
  width: 170px;
}
.tabStyleLi {
  display: inline-block;
  height: 68px;
  position: relative;
}
.tabStyle:hover {
  background: #3246f4 !important;
  opacity: 0.7;
  height: 68px;
  cursor: pointer;
}

.el-image {
  overflow: visible !important;
}
.el-breadcrumb {
  line-height: 44px;
}
</style>
